/**
 *
 * ContentRenderer
 *
 */
// @ts-ignore React not used
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import loadjs from "loadjs";
import { CopyToClipboardButton } from "@happeouikit/copy-to-clipboard-button";
import { BodyUI, sansFamily } from "@happeouikit/typography";
import { media } from "@happeouikit/layout";
import {
  toHtml,
  loadCodeFont,
  decodeHtmlEntities,
  removeSpecialCharacters,
  toSafeText,
  addColorSpanAfterColorCodes,
  replacePTagsInCodeBlocks,
} from "./utils";
import {
  ELEMENT_TYPE_PRE,
  ELEMENT_TYPE_CODE,
  HIGHLIGHJS_CSS,
  HIGHLIGHJS_JS,
  CODE_BLOCK_AVAILABLE_LANGUAGES,
} from "./constants";
import { ContentRendererProps } from "./ContentRenderer.types";

// highlight.js
declare global {
  interface Window {
    hljs: any;
  }
}

const ContentRenderer = ({
  content,
  type,
  widgetType = "post",
  createAnchors,
  useHashAutoscroll,
  hashAutoscrollTopMargin = 0,
  forceDefaultLinkTarget,
  inheritBrandingStyles = false,
  addColorBlocks = false,
}: ContentRendererProps) => {
  const element = useRef<HTMLDivElement>(null);
  const [copyBtns, setCopyBtns] = useState<Element[]>([]);

  // Autoscroll to hashes
  const scrollAndHighlightHash = useCallback(() => {
    try {
      const { hash } = window.location;
      if (element.current && hash) {
        const hashEl = element.current?.querySelector(hash);
        if (hashEl) {
          setTimeout(() => {
            window.scrollTo({
              top: hashEl.getBoundingClientRect().top - hashAutoscrollTopMargin,
              behavior: "smooth",
            });
            hashEl.classList.add("highlight");
            setTimeout(() => {
              hashEl.classList.remove("highlight");
            }, 5000);
          }, 500);
        }
      }
    } catch (error) {
      /* In some cases the hash may be cause this function to fail. In this case, handle and do nothing */
    }
  }, [hashAutoscrollTopMargin]);

  useEffect(() => {
    if (!element.current) return;

    const doCreateAnchors = () => {
      if (!element.current || !createAnchors) {
        return;
      }

      const headerElems =
        element.current.querySelectorAll("h1, h2, h3, h4, h5, h6") || [];
      if (headerElems.length > 0) {
        headerElems.forEach((header) => {
          const { textContent = "" } = header;
          if ((textContent || "").length > 0) {
            header.id =
              header.id ||
              removeSpecialCharacters(
                (textContent || "").toLowerCase().replace(/\s/g, "-")
              );
          }
        });
      }

      setCopyBtns((prevValue) => [...prevValue, ...headerElems]);
    };

    const doForceDefaultLinkTarget = () => {
      if (!element.current || !forceDefaultLinkTarget) {
        return;
      }

      const linkElems = element.current.querySelectorAll("a") || [];
      if (linkElems.length > 0) {
        const currentOrigin = window.origin;
        linkElems.forEach((link) => {
          const { href } = link;
          if (href.startsWith(currentOrigin)) {
            // @ts-ignore - delete needs to be optional
            delete link.target;
          } else {
            link.target = "_blank";
          }
        });
      }
    };

    const doFormatCodeBlocks = () => {
      if (!element.current) {
        return;
      }
      const blockElems = element.current.querySelectorAll(
        ELEMENT_TYPE_PRE
      ) as NodeListOf<HTMLPreElement>;
      if (blockElems.length > 0) {
        if (!loadjs.isDefined("highlightjs")) {
          loadCodeFont();
          loadjs([...HIGHLIGHJS_JS, ...HIGHLIGHJS_CSS], "highlightjs");
        }

        loadjs.ready("highlightjs", () => {
          window.hljs.configure({
            languages: CODE_BLOCK_AVAILABLE_LANGUAGES,
            useBr: true,
          });
          blockElems.forEach((elem) => {
            const plainCode = elem.innerText;

            // Take the inner content and put inside single code block
            // This will ensure nice readability.
            // Needs to be done since editor will generate multiple lines for code for ease of editing
            const codeBlock = document.createElement(ELEMENT_TYPE_CODE);
            const decodedText = decodeHtmlEntities(plainCode);
            codeBlock.textContent = decodedText; // Done like this to prevent xss
            elem.innerHTML = ""; // Clear content so we can set just the single code block there
            elem.appendChild(codeBlock);

            window.hljs.highlightBlock(elem);
          });
          setCopyBtns((prevValue) => [...prevValue, ...blockElems]);
        });
      }
    };

    if (useHashAutoscroll) {
      scrollAndHighlightHash();
    }

    const handleChange = () => {
      doCreateAnchors();
      doForceDefaultLinkTarget();
      doFormatCodeBlocks();
    };

    setTimeout(() => {
      handleChange();
    }, 1);
  }, [
    element,
    createAnchors,
    forceDefaultLinkTarget,
    useHashAutoscroll,
    scrollAndHighlightHash,
  ]);

  const safeText = useMemo(() => {
    const lowercaseType =
      type && typeof type === "string" ? type.toLowerCase() : "html";

    let transformedContent =
      lowercaseType === "text"
        ? `<p>${content}</p>`
        : toHtml(content, lowercaseType);

    if (addColorBlocks) {
      transformedContent = addColorSpanAfterColorCodes(transformedContent);
    }

    transformedContent = replacePTagsInCodeBlocks(transformedContent);

    // Run the xss filter as the last step to ensure safety
    return toSafeText(transformedContent);
  }, [content, type, addColorBlocks]);

  // Use dangerouslySetInnerHTML as there might be nested <p>'s header etc
  // This requires that all content is run through the toSafeText filter
  // before displaying. This will run it through xss filter.
  return (
    <>
      <Wrapper
        className={`content-renderer ${
          widgetType === "page" ? "fr-view pages-text" : ""
        }`}
        ref={element}
        widgetType={widgetType}
        createAnchors={createAnchors}
        dangerouslySetInnerHTML={{
          __html: safeText,
        }}
        inheritBrandingStyles={inheritBrandingStyles}
      />
      {copyBtns.map((el) => {
        let clipboardContent = el.textContent || "";
        if (el.tagName !== ELEMENT_TYPE_PRE) {
          clipboardContent = `${window.location.href.split("#")[0]}#${el.id}`;
        }

        // eslint-disable-next-line no-control-regex
        clipboardContent = removeSpecialCharacters(clipboardContent);

        return ReactDOM.createPortal(
          <BtnContainer className="content-copy-btn">
            <CopyToClipboardButton clipboardContent={clipboardContent} />
          </BtnContainer>,
          el
        );
      })}
    </>
  );
};

const BtnContainer = styled.div`
  display: inline-flex;
  margin-left: var(--space-xs);
  button {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 150ms;
    transition-timing-function: ease-in;
  }
  button:hover,
  button:active,
  button:focus {
    opacity: 1 !important;
  }
  div p {
    font-size: 12px;
    line-height: 16px;
  }
`;

const Wrapper = styled(BodyUI).attrs({ as: "div" })`
  ${({ createAnchors }) =>
    createAnchors
      ? `
    h1, h2, h3, h4, h5, h6 {
      display: flex;
      align-items: center;
      :hover button {
        opacity: 0.4;
      }
    }
  `
      : ""}
  h1.highlight,
  h2.highlight,
  h3.highlight,
  h4.highlight,
  h5.highlight,
  h6.highlight {
    border-radius: var(--radius-lg);
    position: relative;
    :after {
      content: " ";
      position: absolute;
      top: -8px;
      bottom: -8px;
      left: -8px;
      right: -8px;
      border-radius: var(--radius-lg);
      border: 5px solid var(--color-warning-lighten80);
    }
  }

  pre {
    border-radius: var(--radius-md);
    background: var(--color-surface-darken);
    padding: var(--space-sm);
    margin-bottom: var(--space-sm-plus);
    overflow: auto;
    white-space: pre;
    position: relative;    
    *:not(p) {
      font-weight: inherit;
      font-family: "IBM Plex Mono", monospace !important;
    }
    code {
      padding: 0;
      background-color: transparent;
      white-space: pre;
    }
    code p {
      font-family: "IBM Plex Mono",monospace !important;      
    }    
    .content-copy-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: var(--space-sm) 0;
    }
    :hover button {
      opacity: 1;
    }
  }
  code {
      font-family: "IBM Plex Mono",monospace !important;
      padding: var(--space-xs);
      white-space: nowrap;
      font-family: monospace;
      border-radius: var(--radius-md);
      background-color: var(--color-surface-darken);
    }
  .mention {
    border-radius: var(--radii-md);
    background-color: var(--color-yellow50);
    border: 1px solid var(--color-yellow50);
    color: var(--color-primary-text-on-light)
    font-weight: 400;

    &:hover {
      text-decoration: none;
      border: 1px solid var(--color-yellow200);
    }
  }
  .extraMention {
    cursor: inherit;
  }
  .color-block {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-left: var(--space-xxs);
    transform: translateY(4px);
    margin-right: var(--space-xxs);
    border-radius: var(--radius-md);
    border: 1px solid var(--color-gray100);
  }
  ${({ widgetType, inheritBrandingStyles }) =>
    widgetType === "page"
      ? ""
      : `
    * {
      font-family: ${sansFamily};
    }

    h1 {
      font-family: ${
        inheritBrandingStyles
          ? "var(--channels-h1-font-family, revert)"
          : "revert"
      };      
      font-weight: ${
        inheritBrandingStyles ? "var(--channels-h1-font-weight, 500)" : "500"
      };
      font-size: 24px;
      letter-spacing: -0.5px;
      line-height: 32px;
      + h1,
      + h2,
      + h3,
      + h4,
      + p {
        margin-top: var(--space-md);
      }
      & * {
         font-family: ${
           inheritBrandingStyles
             ? "var(--channels-h1-font-family, revert)"
             : "revert"
         };    
      }
    }
    h2 {
      font-family: ${
        inheritBrandingStyles
          ? "var(--channels-h2-font-family, revert)"
          : "revert"
      };      
      font-weight: ${
        inheritBrandingStyles ? "var(--channels-h2-font-weight, 500)" : "500"
      };
      font-size: 20px;
      line-height: 28px;
      + h1,
      + h2,
      + h3,
      + h4,
      + p {
        margin-top: var(--space-md);
      }
      & * {
       font-family: ${
         inheritBrandingStyles
           ? "var(--channels-h2-font-family, revert)"
           : "revert"
       };     
      }
    }
    h3 {
      font-family: ${
        inheritBrandingStyles
          ? "var(--channels-h3-font-family, revert)"
          : "revert"
      };      
      font-weight: ${
        inheritBrandingStyles ? "var(--channels-h3-font-weight, 500)" : "500"
      };
      font-size: 16px;
      line-height: 24px;
      + h1,
      + h2,
      + h3,
      + h4,
      + p {
        margin-top: var(--space-md-minus);
      }
      & * {
        font-family: ${
          inheritBrandingStyles
            ? "var(--channels-h3-font-family, revert);"
            : "revert"
        }   
      }
    }
    h4 {
      font-size: 16px;
      line-height: 24px;
      + h1,
      + h2,
      + h3,
      + h4,
      + p {
        margin-top: var(--space-md-minus);
      }
    }
    p {
      font-family: ${
        inheritBrandingStyles
          ? "var(--channels-body-font-family, revert)"
          : "revert"
      };      
      font-weight: ${
        inheritBrandingStyles
          ? "var(--channels-body-font-weight, revert)"
          : "revert"
      };
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
      + h1,
      + h2,
      + h3,
      + h4,
      + p {
        margin-top: var(--space-md-minus);
      }

      & * {
         font-family: ${
           inheritBrandingStyles
             ? "var(--channels-body-font-family, revert)"
             : "revert"
         };    
      }
  }
    }
    a {
      color: var(--color-active-primary);
      text-decoration: none;
      :hover {
        text-decoration: underline;
      }
    }
    ul {
      list-style-type: disc;
    }
    ol {
      list-style-type: decimal;
      counter-reset: item;
      li {
        padding-left: var(--space-md-minus);
        text-indent: -33px;
        list-style-type: none;
        counter-increment: item;
        &:before {
          display: inline-block;
          width: 13px;
          padding-right: var(--space-md);
          padding-left: var(--space-xs-plus);
          font-weight: bold;
          text-align: right;
          content: counter(item) ".";
        }
        font-family: ${
          inheritBrandingStyles
            ? "var(--channels-body-font-family, revert)"
            : "revert"
        }; 
        font-weight: ${
          inheritBrandingStyles
            ? "var(--channels-body-font-weight, revert)"
            : "revert"
        };
      }
    }
    ol,
    ul {
      font-size: 16px;
      line-height: 24px;
      margin: var(--space-md);
      display: block;
      -webkit-margin-before: var(--space-sm);
      -webkit-margin-after: var(--space-sm);
      -webkit-padding-start: var(--space-sm);
      li,
      li {
        margin-bottom: var(--space-xs-plus);
        padding-left: var(--space-sm-plus);
        > ul,
        > ol {
          -webkit-margin-before: var(--space-sm) !important;
          -webkit-margin-after: var(--space-sm) !important;
        }

        font-family: ${
          inheritBrandingStyles
            ? "var(--channels-body-font-family, revert);"
            : "revert"
        };
        font-weight: ${
          inheritBrandingStyles
            ? "var(--channels-body-font-weight, revert)"
            : "revert"
        };
      }

      &:first-child,
      &:first-child {
        -webkit-margin-before: 0;
      }
      &:last-child,
      &:last-child {
        -webkit-margin-after: 0;
      }
    }
    hr {
      -webkit-margin-before: var(--space-md);
      -webkit-margin-after: var(--space-md-minus);
      border-color: var(--color-divider);
      border-style: solid;
      height: 0px;
      border-width: 1px 0 0 0;
    }
    table {
      width: 100%;
      margin: 15px 0;
      text-align: left;
      thead th {
        background: var(--color-surface);
      }
      th,
      td {
        border-width: 1px;
        border-style: solid;
        border-color: var(--color-divider);
        padding: 0.5em;
      }
      tr:nth-child(even) {
        background: var(--color-surface-darken);
      }
      tr:nth-child(odd) {
        background: var(--color-surface);
      }
    }
    table.no-borders {
      th,
      td {
        border: none;
      }
    }
    table.padded-2 {
      th,
      td {
        padding: 2em;
      }
    }
    table.padded-1 {
      th,
      td {
        padding: 1em;
      }
    }
    img {
      max-width: 100%;
      margin: var(--space-md) 0;
      & + img {
        margin-top: 0;
      }
    }
    .xl-emoji * {
      font-size: 40px;
      line-height: 1.2;
    }
    
    blockquote {
      border-top: 2px solid var(--color-divider);
      border-bottom: 2px solid var(--color-divider);
      font-size: 18px;
      padding: var(--space-lg) var(--space-xxxl);
      position: relative;
      text-align: center;
      margin: var(--space-xxxl) 0;
    }
  `}

  ${({ widgetType, inheritBrandingStyles }) =>
    widgetType === "article" &&
    css`
      h1 {
        font-family: ${inheritBrandingStyles
          ? "var(--channels-h1-font-family, revert)"
          : "revert"};
        & * {
          font-family: ${inheritBrandingStyles
            ? "var(--channels-h1-font-family, revert)"
            : "revert"};
        }
      }
      h2 {
        font-family: ${inheritBrandingStyles
          ? "var(--channels-h2-font-family, revert)"
          : "revert"};
        & * {
          font-family: ${inheritBrandingStyles
            ? "var(--channels-h2-font-family, revert)"
            : "revert"};
        }
      }
      h3 {
        font-family: ${inheritBrandingStyles
          ? "var(--channels-h3-font-family, revert)"
          : "revert"};
        & * {
          font-family: ${inheritBrandingStyles
            ? "var(--channels-h3-font-family, revert)"
            : "revert"};
        }
      }

      h4,
      h5,
      h6,
      p,
      ul,
      ol,
      li,
      table {
        font-family: ${inheritBrandingStyles
          ? "var(--channels-body-font-family, revert)"
          : "revert"};
        & * {
          font-family: ${inheritBrandingStyles
            ? "var(--channels-body-font-family, revert)"
            : "revert"};
        }
      }

      h1 {
        font-size: 32px;
        line-height: 1.13;
        margin: 0 0 20px;
        letter-spacing: -0.75px;
      }

      h2 {
        font-size: 24px;
        line-height: 1.17;
        margin: 0 0 var(--space-md);
        letter-spacing: -0.5px;
      }

      h3 {
        font-size: 20px;
        line-height: 1.2;
        margin: 0 0 14px;
      }

      h4,
      h5,
      h6,
      p,
      ul,
      ol,
      li {
        font-size: var(--font-size-subtitle-sm);
        line-height: 1.56;
        margin-bottom: var(--space-md-minus);
        margin-top: 0;
      }

      hr {
        border-color: var(--color-divider);
      }

      table {
        width: 100% !important;
        margin: var(--space-md) 0;
        font-size: var(--font-size-subtitle-sm) !important;
        text-align: left;
        border-collapse: collapse;
        border-spacing: 0;

        thead th {
          background: var(--color-surface);
        }
        th,
        td {
          border-width: 1px;
          border-style: solid;
          border-color: var(--color-divider);
          padding: var(--space-sm);
          word-wrap: break-word;
        }
        tr:nth-child(odd) {
          background: var(--color-divider-lighten);
        }
        tr:nth-child(even) {
          background: var(--color-surface);
        }
      }

      table.no-borders {
        th,
        td {
          border: none;
        }

        tr:nth-child(odd) {
          background: var(--color-surface-darken);
        }
        tr:nth-child(even) {
          background: var(--color-surface);
        }
      }

      img,
      span.fr-img-caption,
      span.fr-video {
        display: block;
        margin: 30px auto;
        ${media.only.xs`      
         max-width: 100%;
       `};
        &.article-align-left {
          display: block;
          max-width: 100%;
          ${media.min.md`
           margin: 30px 30px 10px 0px;
           float: left;
           max-width: 75%;
         `}
        }
        &.article-align-center {
          display: block;
        }
        &.article-align-right {
          display: block;
          max-width: 100%;
          ${media.min.md`
           margin: 30px 0px 10px 30px;
           float: right;
           max-width: 75%;
         `}
        }
        &.article-align-overflow {
          display: block;
          max-width: 100%;
          ${media.min.md`
             margin: 30px -150px;
             width: calc(100% + 300px) !important;
             max-width: none;
           `}
          img {
            width: 100%;
          }
        }
      }

      img {
        &.image-border-radius {
          border-radius: var(--radius-lg);
        }
        &.image-box-shadow {
          box-shadow: 0 4px 16px 0 #bacad5;
        }
      }

      span.fr-img-caption {
        &.image-border-radius img {
          border-radius: var(--radius-lg);
        }
        &.image-box-shadow img {
          box-shadow: 0 4px 16px 0 #bacad5;
        }
      }

      p {
        img:first-child,
        span.fr-img-caption:first-child {
          &.article-align-left,
          &.article-align-right {
            ${media.min.md`
            margin-top: var(--space-sm);
           `}
          }
        }
      }

      span.fr-img-caption {
        text-align: center;
        img {
          margin: 0;
        }
        .fr-inner {
          font-weight: 400;
          font-size: 16px;
          margin: var(--space-sm) auto 0 auto;
          padding: 0 var(--space-sm);
          color: var(--color-secondary-text-on-light);
          display: block;
          max-width: 671px;
        }
      }

      span.fr-video {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;

        border-radius: var(--radius-lg);

        &.article-align-overflow {
          padding-top: 170px;
        }
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100% !important;
          height: 100% !important;
        }
      }

      blockquote {
        border-left: 0 !important;
        padding-left: 0 !important;
        margin: 0 !important;
        color: inherit !important;

        p {
          line-height: 30px;
          font-size: 26px;
          text-align: center;
          margin: 0 auto;
        }
      }

      .content-attachments {
        width: 100%;
      }
    `}
  }
`;

export default ContentRenderer;
