import xss from "xss";
import linkifyHtml from "linkify-html";
import { decode as decodeHtmlEntities } from "html-entities";
// @ts-ignore no types available
import { fixEmojioneBug, shortToUnicode } from "@happeouikit/emojis";
import { MONOSPACE_FONT_URL, safeTagsAndAttributes } from "./constants";
import htmlConverter from "./htmlConverter";
import { extraMentioning, mentioningRegex } from "./mentioningUtils";

const mentioningRegexGroupCaseInsensitive = new RegExp(mentioningRegex, "gi");
const mentioningHtmlRegexGroupCaseInsensitive = new RegExp(
  /<user-mentio[^>]*data-user-id=["']([^"]+)["'][^>]*>(?:@|(?:&#64;))(.+?)<\/user-mentio>/,
  "gi"
);

const processMatch = (_: any, id: string, name: string): string => {
  if (
    extraMentioning.find(
      (mentioning) => mentioning.toLowerCase() === String(id).toLowerCase()
    )
  ) {
    return `<a class="mention extraMention" data-user-id="${id}">@${name}</a>`;
  }
  return `<a class="mention" data-user-id="${id}">@${name}</a>`;
};

const processMentions = (str = "") =>
  str
    .replace(mentioningRegexGroupCaseInsensitive, processMatch)
    .replace(mentioningHtmlRegexGroupCaseInsensitive, processMatch);

/**
 * @name toSafeText
 * @description Returns filtered text with filterXSS library
 * @author Antero Hanhirova
 * @param {String} - Text to whitelist
 * @returns {String}
 */
const toSafeText = (text = "") => {
  return xss(text, {
    whiteList: safeTagsAndAttributes,
    css: {
      whiteList: {
        // @ts-ignore method not found
        ...xss.getDefaultCSSWhiteList(),
        "vertical-align": true,
      },
    },
    stripIgnoreTag: true,
  })
    .replace(/&nbsp;/g, " ")
    .replace(/\u2060/g, ""); // Replaces a hidden character that resulted in a "?"-character in backend
};

/**
 * Adds spans with `color-block` class after color codes hex, rgb and rgba
 */
const addColorSpanAfterColorCodes = (text = "") => {
  const colorRegex = /#(?:[0-9a-fA-F]{3}){1,2}|\brgb\(\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}\s*\)|\brgba\(\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d*(?:\.\d+)?\s*\)/gi;
  return text.replace(
    colorRegex,
    (match) =>
      `${match}<span class="color-block" style="background-color: ${match};"></span>`
  );
};

const addLinksToText = (text = "") => {
  try {
    return linkifyHtml(text, {
      target: "_blank",
      ignoreTags: ["pre", "code", "script", "style"],
      render: ({ tagName, attributes, content }) => {
        let newContent = content;
        if (newContent.length > 85) {
          // Truncate long urls from the middle
          const start = newContent.slice(0, 40);
          const end = newContent.slice(-40);
          newContent = `${start}[...]${end}`;
        }
        const attrs = Object.keys(attributes).reduce((acc, key) => {
          const attr = attributes[key];
          return `${acc} ${key}="${attr}"`;
        });
        return `<${tagName} ${attrs}>${newContent}</${tagName}>`;
      },
      validate: {
        url(value: string) {
          return /^(http|ftp)s?:\/\//.test(value);
        },
      },
    });
  } catch (error) {
    return text;
  }
};

/**
 * @name toHtml
 * @description Converts markdown to html, adds space to end of String
 *               The non-breaking space helps users to add text if last word is mention item
 * @author Antero Hanhirova
 * @param {String} - Markdown
 * @returns {String} - Html
 */

const toHtml = (str = "", type = "html") => {
  let newString = shortToUnicode(str);

  newString = fixEmojioneBug(newString);

  if (type === "markdown") {
    newString = htmlConverter.makeHtml(newString);
  } else {
    newString = processMentions(newString);
  }

  // Decoding is required for linkify to work
  newString = decodeHtmlEntities(newString);
  newString = addLinksToText(newString);
  return newString;
};

const loadCodeFont = () => {
  const id = "ibmMonoOnDemand";
  if (!document.getElementById(id)) {
    const style = document.createElement("style");
    style.id = id;
    style.appendChild(
      document.createTextNode(`@import url(${MONOSPACE_FONT_URL});`)
    );
    document.head.appendChild(style);
  }
};

const getNearestRelativeParent = (el: HTMLElement): HTMLElement | null => {
  if (!el) {
    return null;
  }
  const elStyle = el.style || window.getComputedStyle(el, "");
  const positionStyle = elStyle.position;
  if (["relative", "absolute"].includes(positionStyle)) {
    return el;
  }
  return getNearestRelativeParent(el.parentNode as HTMLElement);
};

// Remove special and hidden characters from string
const removeSpecialCharacters = (str = "") =>
  // eslint-disable-next-line no-control-regex
  str.replace(/[^\u0000-\u007E]/g, "");

const replacePTagsInCodeBlocks = (html = "") => {
  // Regex to match <pre> and <code> blocks with optional attributes
  const regex = /<pre\b[^>]*>\s*<code\b[^>]*>([\s\S]*?)<\/code>\s*<\/pre>/g;

  const replacedHtml = html.replace(regex, (match) => {
    return match
      .replace(/<\/p>\s*<p>/g, "\n") // Replace </p><p> with a newline
      .replace(/<p>/g, "") // Remove opening <p> tags
      .replace(/<\/p>/g, ""); // Remove closing </p> tags
  });

  // Encode content to prevent XSS
  const encodedCodeBlocks = replacedHtml.replace(regex, (match) => {
    return xss(match, {
      whiteList: {
        pre: [],
        code: [],
      },
    });
  });

  return encodedCodeBlocks;
};

export {
  toHtml,
  toSafeText,
  loadCodeFont,
  decodeHtmlEntities,
  getNearestRelativeParent,
  removeSpecialCharacters,
  addColorSpanAfterColorCodes,
  replacePTagsInCodeBlocks,
};
