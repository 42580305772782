import { User } from "./Avatar.types";

export const getNameString = (user?: User) => {
  if (!user) return "";
  if (typeof user.name === "object") {
    return user.name?.fullName || user.fullName || user.displayName;
  }

  return user.fullName || user.displayName || user.name;
};

const splitName = (name: string) => {
  return name
    .split(/\s+/)
    .filter((s) => s.length > 0)
    .map((s) => s.trim().toUpperCase());
};

export const getInitials = (user?: User) => {
  const fullName = getNameString(user);
  if (!fullName) return "?";
  const names = splitName(fullName);
  if (names.length === 0) return "?";
  return names.length === 1
    ? names[0][0]
    : names[0][0] + names[names.length - 1][0];
};

const initialsBackgroundColors = [
  "var(--color-navy800)",
  "var(--color-navy600)",
  "var(--color-green800)",
  "var(--color-green600)",
  "var(--color-red800)",
  "var(--color-red600)",
  "var(--color-orange800)",
  "var(--color-orange600)",
  "var(--color-yellow800)",
  "var(--color-yellow600)",
  "var(--color-violet800)",
  "var(--color-violet600)",
  "var(--color-pink800)",
  "var(--color-pink600)",
  "var(--color-blue800)",
  "var(--color-blue600)",
];

export const getInitialsBgColor = (user?: User) => {
  if (getInitials(user) === "?") return "var(--color-gray600)";
  const name = getNameString(user);

  const hashNumber = name
    ? name.split("").reduce((hash, char) => {
        return char.charCodeAt(0) + (hash << 6) + (hash << 16) - hash;
      }, 0)
    : 0;

  const index = Math.abs(hashNumber) % initialsBackgroundColors.length;
  return initialsBackgroundColors[index];
};
